import axios from "axios";
import Swal from 'sweetalert2';

 axios.defaults.baseURL = "https://scripts.realdeals.net";
// axios.defaults.baseURL = 'http://localhost:8082';

axios.interceptors.request.use(
  async (config) => {
    const authUser = await localStorage.getItem("authRealDeals");
    const user = JSON.parse(authUser);

    if (user) {
      const token = user.token;
      // config.headers.accessToken = token;
      config.headers = {
        "Content-Type": "application/json",
        "x-access-token": token,
      };
    } else {
      config.headers = { "Content-Type": "application/json" };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use((response) => response, (error) => { 
  if(error.toJSON().message === 'Network Error'){
    Swal.fire('Check your internet connection!'); 
    return false;
  }
  if (error.response && (error.response.status === 401 || error.response.status === 403) ) {
    Swal.fire("Your session is expired! Login again to continue.") 
    .then((value) => {
      localStorage.clear(); 
      window.location = window.location.origin+'/login';
    });
  }
});